
import { Component, OnInit, Inject, OnDestroy, Injectable, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, throwError } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LocalStorageUtils } from './shared/utils/localstorage';
import { SetDefaultLanguageService } from './shared/utils/setDefaultLanguage';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { AuthenticationResult, InteractionStatus, InteractionType, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Logger } from './shared/logger.service';
import { stringToBool } from './shared/utils/booleansUtil';
import { NgxSpinnerService } from "ngx-spinner";
import { TranslateService } from "@ngx-translate/core";
import { AppInsightsService } from 'src/azure-monitor';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'], encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit, OnDestroy{
  title = 'Parts Commercial';
  isIframe = false;
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();
  public LocalStorage = new LocalStorageUtils();
  static globalRole: any;
  groupIds: any[] = [];
  groups: any;

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private router:Router,
    private http: HttpClient,
    private spinner: NgxSpinnerService,
    private translateService: TranslateService,
    private setLang: SetDefaultLanguageService,
  ) {
    AppComponent.globalRole = '';
  }

  get staticGlobalRole() {
    return AppComponent.globalRole;
  }

  ngOnInit(): void {
    AppInsightsService.loadAppInsights();
    AppInsightsService.logFeatureUsage("Teste App Insights");
    this.translateService.setDefaultLang(this.setLang.setDefaultLanguage())
    this.translateService.use(localStorage.getItem('language') || this.setLang.setDefaultLanguage())

    this.spinner.show()
     this.isIframe = window !== window.parent && !window.opener;
     this.msalBroadcastService.inProgress$
       .pipe(
         filter((status: InteractionStatus) => status === InteractionStatus.None),
         takeUntil(this._destroying$)
       )
       .subscribe(() => {
         this.setLoginDisplay();
         this.getAccessTokenAndCallGraphAPI();
       });
       this.spinner.hide()
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
    this.setLocaStorageToken();
    this.setCountryByUserToken()
    this.setLanguageByUserToken();
  }

  setLocaStorageToken(){
    if(this.authService.instance.getAllAccounts()[0] == undefined){
      this.router.navigate(['/']);
    }

    let environment = this.authService.instance.getAllAccounts()[0].environment;
    let homeAccountId = this.authService.instance.getAllAccounts()[0].homeAccountId;
    let idTokenClaims = <any>this.authService.instance.getAllAccounts()[0].idTokenClaims;



    localStorage.setItem('TokenKey', homeAccountId + '-' + environment + '-idtoken-' + idTokenClaims.aud + "-" + idTokenClaims.tid + "---");
    localStorage.setItem('TokenClaims', homeAccountId + '-' + environment + "-" + idTokenClaims.tid);
    localStorage.setItem('TokenAccess', homeAccountId + '-' + environment + '-accesstoken-' + idTokenClaims.aud + "-" + idTokenClaims.tid + '-groupmember.read.all profile user.read user.read.all openid email'+"--");
    this.setGraphToken();
  }

  public setCountryByUserToken(): void{
    let key = <any>localStorage.getItem('TokenClaims');
    let isSet = <any>localStorage.getItem('countryCode');

    if(isSet === null || isSet === 'undefined'){
      let token = <any>localStorage.getItem(key.toString());
      <any>localStorage.setItem('countryCode', JSON.parse(token).idTokenClaims.ctry);
    }
  }

  setLanguageByUserToken(){
    let isSet = <any>localStorage.getItem('language');

    if(isSet === null || isSet === 'undefined'){
      <any>localStorage.setItem('language', 'br');
    }
  }

  login() {
    if (stringToBool(environment.IS_PRODUCTION)) {
      Logger.enableProductionMode();
    }
    
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest){
        this.authService.loginPopup({...this.msalGuardConfig.authRequest} as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
        } else {
          this.authService.loginPopup()
            .subscribe((response: AuthenticationResult) => {
              this.authService.instance.setActiveAccount(response.account);
            });
      }
    } else {
      if (this.msalGuardConfig.authRequest){
        this.authService.loginRedirect({...this.msalGuardConfig.authRequest} as RedirectRequest);
      } else {
        this.authService.loginRedirect();
      }
    }
  }

  logout() {
    // The line below was not working properly, we need to check it, to understand the reason why.

    // this.authService.logout(); //

    //Because of it, we are using a simple redirect to the Volvo SharePoint instead.

    window.location.replace("https://volvogroup.sharepoint.com/Pages/start.aspx");
  }

  protected serviceError(response: Response | any) {
    let customError: string[] = [];

    if (response.statusText === "Unknown Error") {
      customError.push("Ocorreu um erro desconhecido");
      response.error.errors = customError;
    }

    if (response.status === 401) {
      localStorage.clear();
      document.location.href = "/";
    }
    return throwError(response);
  }

  getUserProfileRole() {
    let key = <any>localStorage.getItem('TokenClaims');
    let token = <any>localStorage.getItem(key.toString());
    AppComponent.globalRole = JSON.parse(token).idTokenClaims.roles;


    //let groupIds = this.LocalStorage.getUserGroups();
    // let ocpToken = environment.OcpToken;
    // const headers = new HttpHeaders({
    //   'Ocp-Apim-Subscription-Key': ocpToken,
    //   'Authorization': `Bearer ${this.LocalStorage.getUserToken()}`,
    //   'Language-Code': `${this.LocalStorage.getCurrentLanguage()}`,
    // })
    // this.http.post<Role>(environment.UrlAuthorization + "Role/GetByIdList", { ids: groupIds }, {headers: headers}).subscribe(
    //   role => {AppComponent.globalRole = role},
    //   error => {this.serviceError(error)}
    // );
  }

  public getAccessTokenAndCallGraphAPI() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.LocalStorage.getAccessToken()}`
      })
    }

    this.http.get("https://graph.microsoft.com/v1.0/me/transitiveMemberOf/microsoft.graph.group?$count=true&$select=id&$top=999", httpOptions).subscribe(result => {
     this.groups = result;
     this.groups.value.forEach((group: { id: any; }) => {
      this.groupIds.push(group.id)
     });
    });
    this.getUserProfileRole()
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  setGraphToken() {
    let account = <any>this.authService.instance.getAllAccounts()[0];
    const graphAccessTokenRequest = {
      scopes: ['https://graph.microsoft.com/.default'],
      account: account,
      authority: environment.ENVIRONMENT_AUTHORITY
    };

    this.authService.instance.acquireTokenSilent(graphAccessTokenRequest).then(response => {
      localStorage.setItem('GraphAccessToken', response.accessToken);
    });
  }

}
