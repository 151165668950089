// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  EnvironmentPlaceName: 'QA',
  production: false,
  // admin:'f3c4b050-92ef-421d-95fd-142dd2c6d129',
  // adminDirectCustomer:'290ddd06-177b-496a-b480-f271d20276a5',
  // manager:'41220d9d-9316-42fd-b45b-6224e98bfd27',
  // viewer:'763adb61-b68f-4640-bb90-65cde736a8dc',
  clientId: '35b8d74d-2230-42a0-8df4-65239f67a5a3',
  authority: 'https://login.microsoftonline.com/f25493ae-1c98-41d7-8a33-0be75f5fe603',
  redirectUri: 'https://partscommercial-qa.volvo.com/',
  postLogoutRedirectUri: 'https://login.microsoftonline.com/common/oauth2/v2.0/logout',
  OcpToken: '',
  GRAPH_ENDPOINT: 'https://graph.microsoft.com/v1.0/me',
  //API Paths
  // UrlApiLaamre: 'https://laamre-api-dev.azurewebsites.net/api/'
  UrlApiPartsOrderBook: 'https://partsorderbook-api-qa.volvo.com/v1/',
  logisticManagerGroupId: '883be837-8558-4cfb-88ee-4790d2207cf1',
  pricingManagerGroupId: '66a1a524-0cf4-4c56-8910-a0dce1fd72f3',
  salesRepresentativeAdminGroupId: '69b119e5-6309-4824-baed-f5cd5766e104',
  salesRepresentativeGroupId: '3b770244-f387-483c-b864-80b24fb82fc5',
  itGroupId: '1dcf6240-ee19-42c3-81af-a735b04bd737',
  externalUsersGroupId: '8c13aa3b-531e-46ae-8350-d3fb4d8f75d3',
  APPLICATIONINSIGHTS_INSTRUMENTATION_KEY: "15e73e3d-4c58-406e-843e-946b4fa09506",
  APPLICATIONINSIGHTS_CONNECTION_STRING: "InstrumentationKey=15e73e3d-4c58-406e-843e-946b4fa09506;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/;ApplicationId=448691ef-126e-432f-b03a-4162c457a21c"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
