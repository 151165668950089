// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  ENVIRONMENT_PLACE_NAME: 'QA',
  IS_PRODUCTION: 'false',
  CLIENT_ID: '35b8d74d-2230-42a0-8df4-65239f67a5a3',
  ENVIRONMENT_AUTHORITY: 'https://login.microsoftonline.com/f25493ae-1c98-41d7-8a33-0be75f5fe603',
  REDIRECT_URI: 'https://partscommercial-qa.volvo.com/',
  POST_LOGOUT: 'https://login.microsoftonline.com/common/oauth2/v2.0/logout',
  OcpToken: '',
  GRAPH_ENDPOINT: 'https://graph.microsoft.com/v1.0/me',
  URL_API_PARTS_ORDER_BOOK: 'https://partsorderbook-api-qa.volvo.com/v1/',
  LOGISTIC_MANAGER_GROUP_ID: '883be837-8558-4cfb-88ee-4790d2207cf1',
  PRINCING_MANAGER_GROUP_ID: '66a1a524-0cf4-4c56-8910-a0dce1fd72f3',
  SALES_REPRESENTATIVE_ADMIN_GROUP_ID: '69b119e5-6309-4824-baed-f5cd5766e104',
  SALES_REPRESENTATIVE_GROUP_ID: '3b770244-f387-483c-b864-80b24fb82fc5',
  IT_GROUP_ID: '1dcf6240-ee19-42c3-81af-a735b04bd737',
  EXTERNAL_USERS_GROUP_ID: '8c13aa3b-531e-46ae-8350-d3fb4d8f75d3',
  APPLICATIONINSIGHTS_INSTRUMENTATION_KEY: "15e73e3d-4c58-406e-843e-946b4fa09506",
  APPLICATIONINSIGHTS_CONNECTION_STRING: "InstrumentationKey=15e73e3d-4c58-406e-843e-946b4fa09506;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/;ApplicationId=448691ef-126e-432f-b03a-4162c457a21c"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

